import * as React from 'react'
import styled from 'styled-components'
import { navigate, PageProps } from 'gatsby'

import Layout from 'src/layouts'
import SEO from 'src/components/shared/SEO'
import BackgroundWrapper from 'src/components/Consultation/BackgroundWrapper'
import { TransitionScreen } from 'src/components/Consultation/screen-types'
import { useConsultation } from 'src/hooks/useConsultation'
import { media } from 'src/styles/util'

const PaddingWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  ${media.mobile`
    padding: 0 15px;
  `}
  ${media.tablet`
    padding: 0 50px;
  `}
`

const Container = styled.div`
  background-color: white;
  border-radius: 25px;
  height: 100%;
  width: 100%;
  min-height: 400px;
  display: grid;
  place-items: center;
  margin: 0 auto;
  ${media.mobile`
    padding: 40px 15px;
  `}
  ${media.tablet`
    padding: 40px 50px;
  `}
  ${media.desktop`
    padding: 40px 50px;
    max-width: 1200px;
  `}
`

const SorryPage: React.FC<PageProps> = ({ location }) => {
  const { answers } = useConsultation()
  const { pet_name } = answers

  const screen = {
    mainCopy: `Our supplements are naturally flavored with beef liver, cheese, and bacon. Based on ${pet_name}’s allergies, please consult your veterinarian if you’d like to proceed.`,
    subCopy: 'Any questions? Send us a woof at hello@petfinn.com!',
    transitionDuration: 20000,
    type: 'transition',
    nextCopy: 'Home'
  }

  return (
    <>
      <SEO title="Consultation" location={location} />
      <Layout location={location}>
        <BackgroundWrapper>
          <PaddingWrapper>
            <Container>
              <TransitionScreen screen={screen} next={() => navigate('/')} answers={answers} />
            </Container>
          </PaddingWrapper>
        </BackgroundWrapper>
      </Layout>
    </>
  )
}

export default SorryPage
